<template>
  <div class="container">
    <!-- 课程审核 -->
    <header-bar></header-bar>
    <div class="yh_filter bg_white">
      <el-form class="form" :inline="true">
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程状态">
              <el-select
                clearable
                v-model="formData.state"
                placeholder="请选择"
              >
                <el-option value="" label="全部"></el-option>
                <el-option
                  v-for="item in courseState"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="label.medicalLabel">
              <el-cascader
                v-model="formData.subjectId"
                :options="subOptions"
                :props="subProps"
                clearable
                @change="medicalChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="label.courseLabel">
              <el-select
                v-model="formData.labels"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in courseLabels"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictLabel"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建人">
              <el-input
                clearable
                v-model="formData.creatorName"
                placeholder="请输入姓名"
                maxlength="10"
                show-word-limit
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="课程类型">
              <el-radio-group v-model="formData.courseType">
                <el-radio :label="2">点播</el-radio>
                <el-radio :label="1">直播</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button
              icon="el-icon-search"
              class="btn btn_blue"
              @click="search"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table">
      <el-table :data="tableData" tooltip-effect="dark" style="width: 100%">
        <el-table-column
          label="课程标题"
          prop="courseTitle"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="课程类型">
          <template slot-scope="scope">
            <span>{{ scope.row.courseType == 1 ? "直播" : "录播" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="label.medicalLabel"
          prop="subjectName"
        ></el-table-column>
        <el-table-column
          :label="label.courseLabel"
          prop="labels"
        ></el-table-column>
        <el-table-column
          label="创建人"
          prop="creatorName"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.creatorName ? scope.row.creatorName : "--"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="课程状态">
          <template slot-scope="scope">
            <span
              :class="{
                color_orange: scope.row.state == 2,
                color_green: scope.row.state == 1,
                color_red: scope.row.state == 3,
                color_gray: scope.row.state == 9,
              }"
              >{{ scope.row.stateName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <span class="operate color_blue" @click="detail(scope.row)"
              >预览</span
            >
            <span class="operate color_blue" @click="auditRecords(scope.row)"
              >审核记录</span
            >
            <span
              v-if="scope.row.state == 2"
              class="operate color_orange"
              @click="review(scope.row)"
              >审核</span
            >
            <!-- || scope.row.state == 2 -->
            <span
              v-if="scope.row.state == 1"
              class="operate color_red"
              @click="updateCourse(scope.row)"
              >下架</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      :pageSize="formData.pageSize"
      :total="totalCount"
      @pageChange="pageChange"
      ref="pageRef"
    />
    <courseVerificationCode :courseId="courseId" :path="currentPath" />
    <el-dialog
      class="dialog_wrapper"
      v-if="isReviewDialogVisible"
      :visible.sync="isReviewDialogVisible"
      :close-on-click-modal="false"
      width="480"
    >
      <check :courseId="courseId" @changeDialogClose="closeDialog"></check>
    </el-dialog>
    <el-drawer
      :visible.sync="isDrawerVisible"
      :with-header="false"
      :wrapperClosable="true"
    >
      <div class="gl_drawer">
        <div class="gl_drawer_title">
          <span
            class="gl_state"
            :class="{
              color_orange: currentState == 2,
              color_green: currentState == 1,
              color_red: currentState == 3,
              color_gray: currentState == 9,
            }"
            >{{ currentStateName }}</span
          >
          <h2 :title="courseTitle">{{ courseTitle }}</h2>
        </div>
        <div class="gl_drawer_cont">
          <p class="gl_drawer_t">审批历史</p>
          <ul v-if="auditCount > 0">
            <li v-for="item in auditList" :key="item.logId">
              <span class="gl_audit_user">{{ item.operatorName }}</span
              >{{ item.operation
              }}<span class="gl_audit_time">{{ item.operateTime }}</span>
              <p v-if="item.remark" class="gl_audit_info">
                "{{ item.remark }}"
              </p>
            </li>
          </ul>
          <p v-else class="gl_empty">——— 暂无审批数据 ———</p>
        </div>
        <pagination
          class="hh_page"
          v-if="auditCount > recordData.pageSize"
          :pageSize="recordData.pageSize"
          :total="auditCount"
          @pageChange="auditPageChange"
          ref="child"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import headerBar from "@comps/backStageComponent/layout/headerBar/headerBar";
import Pagination from "@comps/backStageComponent/pagination";
import courseVerificationCode from "@/components/backStageComponent/courseVerificationCode";
import check from "./component/checkDetail";
import API from "@/apis/studentsEndAPI/liveListAPI";
import dictionaryAPI from "@/apis/backStageEndAPI/dictionaryManagementAPI";
import AuditAPI from "@/apis/backStageEndAPI/courseReviewAPI";
import CourseAPI from "@/apis/backStageEndAPI/myCourseAPI";
import CommonUtils, { formateTreeChildren } from "@utils/index.js";
export default {
  name: "courseCheck",
  components: {
    headerBar,
    Pagination,
    courseVerificationCode,
    check,
  },
  data() {
    return {
      totalCount: 0,
      tableData: [],
      subOptions: [], //学科数组
      formData: {
        courseType: 2, //课程类型：1|直播课，2|章节录播课
        page: 1, //页码
        pageSize: 15, //	分页大小
        createStartTime: "", //创建开始时间
        createEndTime: "", //创建截止时间
        keyword: "", //模糊查询
        labels: "", //标签，多标签逗号拼接
        state: "", //课程状态
        subjectId: "", //学科id
        creatorName: "", //创建人
      },
      courseState: [
        //课程状态
        // {id: 0, label: '未发布'},
        { id: 1, label: "已发布" },
        { id: 2, label: "待审核" },
        { id: 3, label: "已驳回" },
        { id: 9, label: "已下架" },
      ],
      subProps: {
        value: "medicalId",
        label: "medicalName",
        children: "children",
        checkStrictly: true,
      },
      courseLabels: [],
      stateMap: "",
      courseId: "",
      currentPath: "",
      isReviewDialogVisible: false,
      courseTitle: "课程标题",
      isDrawerVisible: false,
      recordData: {
        page: 1,
        pageSize: 10,
      },
      auditCount: 0,
      currentRow: "",
      auditList: [],
      currentState: "",
      currentStateName: "",
      label: {},
    };
  },
  mounted() {
    this.label = this.$store.state.label;
    this.initState();
    this.getMedicalList();
    this.getCourseLabel();
    this.getAuditList();
  },
  methods: {
    initState() {
      this.stateMap = new Map();
      this.stateMap.set(0, "未发布");
      this.stateMap.set(1, "已发布");
      this.stateMap.set(2, "待审核");
      this.stateMap.set(3, "已驳回");
      this.stateMap.set(9, "已下架");
    },
    search() {
      this.formData.page = 1;
      this.$refs.pageRef.page = 1;
      this.getAuditList();
    },
    getAuditList() {
      AuditAPI.getAuditList(CommonUtils.parseToParams(this.formData)).then(
        (res) => {
          this.tableData = res?.dataList;
          this.tableData.length &&
            this.tableData.forEach((item) => {
              this.$set(item, "stateName", this.stateMap.get(item.state));
              // item.stateName = this.stateMap.get(item.state)
            });
          this.totalCount = res.rowCount;
        }
      );
    },
    async getMedicalList() {
      //医学学科
      let status = "1"; //1正常0停用
      let data = await API.getMedicalList(status);
      if (data && !data.status) {
        this.subOptions = formateTreeChildren(data);
      }
    },
    getCourseLabel() {
      //获取课程标签
      dictionaryAPI.getDicData("course_type").then((res) => {
        this.courseLabels = res;
      });
    },
    medicalChange(val) {
      if (val) {
        this.formData.subjectId = val[val.length - 1];
      } else {
        this.formData.subjectId = "";
      }
    },
    detail(item) {
      if (item.courseType == 1) {
        //直播
        this.currentPath = "/checkLiveDetail";
      } else {
        this.currentPath = "/checkdibbleDetail";
      }
      if (item.courseLimits === 0) {
        this.navigator(item);
      } else if (item.courseLimits === 1) {
        if (!sessionStorage.getItem("educationToken")) {
          this.$store.state.isLogin = true;
        } else {
          this.navigator(item);
        }
      } else if (item.courseLimits === 2) {
        this.courseId = item.courseId;
        this.$store.state.isVerifi = true;
      }
    },
    navigator(item) {
      this.$router.push({
        path: this.currentPath,
        query: {
          dictName: item.courseTitle,
          // dictType: row.dictType,
          // dictId: row.dictId,
          id: item.courseId,
        },
      });
    },
    async auditRecords(item) {
      this.currentRow = item;
      this.currentState = item.state;
      this.currentStateName = this.stateMap.get(item.state);
      this.courseTitle = item.courseTitle;
      let data = await AuditAPI.auditRecord(
        item.courseId,
        CommonUtils.parseToParams(this.recordData)
      );
      this.auditList = data.dataList;
      this.auditCount = data.rowCount;
      this.isDrawerVisible = true;
    },
    pageChange(val) {
      //页面跳转
      this.formData.page = val;
      this.getAuditList();
    },
    review(item) {
      this.courseId = item.courseId;
      this.isReviewDialogVisible = !this.isReviewDialogVisible;
    },
    closeDialog() {
      this.isReviewDialogVisible = false;
      this.courseId = "";
      this.getAuditList();
    },
    updateCourse(item) {
      this.$confirm("确定要下架该课程吗？", {})
        .then((res) => {
          let params = {
            courseId: item.courseId,
            state: 9,
          };
          CourseAPI.updateCourse(params).then((res) => {
            this.getAuditList();
          });
        })
        .catch(() => {});
    },
    auditPageChange(val) {
      //页面跳转
      this.recordData.page = val;
      this.auditRecords(this.currentRow);
    },
  },
};
</script>

<style scoped lang="scss">
.color_gray {
  color: #dcdcdc;
}
.yh_filter {
  margin-bottom: 16px;
  padding: 16px 16px 0;
  border-radius: 12px;
}
.gl_drawer {
  width: 100%;
  box-sizing: border-box;
  .gl_drawer_title {
    position: relative;
    padding: 0 40px 0 70px;
    background: var(--colorBlue);
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    h2 {
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .gl_state {
      position: absolute;
      top: 50%;
      left: 8px;
      padding: 4px 8px;
      border-radius: 16px;
      background: #fff;
      font-size: 12px;
      line-height: 100%;
      transform: translateY(-50%);
    }
  }
  .gl_empty {
    color: #666;
    text-align: center;
  }
  li {
    margin-bottom: 16px;
    padding: 8px;
    background: #f7faff;
    .gl_audit_user {
      padding-right: 6px;
      color: var(--colorBlue);
    }
    .gl_empty {
      color: #666;
      text-align: center;
    }
    li {
      margin-bottom: 16px;
      padding: 8px;
      background: #f7faff;
      .gl_audit_user {
        padding-right: 6px;
        color: var(--colorBlue);
      }
      .gl_audit_time {
        float: right;
        color: #666;
      }
      .gl_audit_info {
        padding: 4px;
      }
    }
  }
}
.hh_page {
  min-width: 115%;
}
::v-deep .el-pagination__jump {
  margin-left: 0;
}
</style>
